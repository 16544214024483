<template>
  <v-expansion-panel v-if="podeEditarCampos">
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('title.editar_acao', 1) }}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <planejamento-acao-edicao-form
        :exibir-edicao="exibirEdicao"
        :is-card="false"
        :acao-edicao="acaoEdicao"
        :id-acao="idAcao"
        :configuracao="configuracao"
        @PlanejamentoAcaoEdicaoForm__atualizado="$emit('PlanejamentoAcaoEdicaoPanel__atualizado')">
      </planejamento-acao-edicao-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import PlanejamentoAcaoEdicaoMixin from './PlanejamentoAcaoEdicaoMixin';
import PlanejamentoAcaoEdicaoForm from './PlanejamentoAcaoEdicaoForm';

export default {
  name: 'PlanejamentoAcaoEdicaoPanel',
  components: { PlanejamentoAcaoEdicaoForm },
  props: {
    acaoEdicao: null,
    idAcao: null,
  },
  mixins: [
    PlanejamentoAcaoEdicaoMixin,
  ],
};
</script>
