import { generateComputed } from '../../../common/functions/roles-computed-generator';
import AcaoConfiguracao from '../AcaoConfiguracao';

export default {
  props: {
    exibirEdicao: Boolean,
    configuracao: Object,
  },
  data() {
    return {
      camposEdicao: [],
      editaDescricao: false,
      configuracaoAcao: null,
    };
  },
  computed: {
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    temCampoEdicao() {
      return this.camposEdicao && this.camposEdicao.length > 0;
    },
    podeEditarCampos() {
      return this.canEdit && this.exibirEdicao
        && (this.temCampoEdicao || this.editaDescricao)
        && this.configuracaoAcao;
    },
  },
  mounted() {
    this.configuracaoAcao = new AcaoConfiguracao(this.configuracao);
    this.camposEdicao = this.configuracaoAcao.camposEdicaoAposAprovacao();
    this.editaDescricao = this.configuracaoAcao.campoPadraoEdicaoAposAprovado('descricao');
  },
};
