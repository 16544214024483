<template>
  <component :is="isCard ? 'v-card' : 'div'" v-if="configuracaoAcao && podeEditarCampos">
    <v-toolbar dark color="primary" v-if="toolbar">
      <v-btn icon dark @click="fechar">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>{{`${$tc('title.editar_acao', 1)} - ${idAcao}`}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="toolbarAfter"></slot>
    </v-toolbar>
    <v-card-title class="mx-0 px-4 py-2" v-else-if="cardTitle">
      {{`${$tc('title.editar_acao', 1)} - ${idAcao}`}}
    </v-card-title>
    <v-form
      ref="formEdicaoAcao"
      v-model="valid"
      lazy-validation>
      <v-container fluid grid-list-md class="pa-0">
        <metadados-container-layout
          v-if="temCampoEdicao"
          :metadados-entidade="metadadosParametrizado"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :col-class="colClass"
          :objeto="acao"
          :campos-formulario="camposFormulario"
          :ordenacao-campos="camposEdicao"
          :explode-hierarquia="true"
          ref="container">
          v-col cols="12">
          <v-col cols="12" :sm="sm6 ? 6 : 12" :class="colClass" slot="antes">
            <v-text-field
              id="descricao"
              v-model="acao.descricao"
              :disabled="!editaDescricao"
              :rules="[rules.required]"
              :label="`${$tc('label.descricao_planejamento_acao', 1)} *`"/>
          </v-col>
        </metadados-container-layout>
        <v-row :class="layoutClass" v-else-if="editaDescricao">
          <v-col cols="12" :sm="sm6 ? 6 : 12" :class="colClass" slot="antes">
            <v-text-field
              id="descricao"
              v-model="acao.descricao"
              :rules="[rules.required]"
              :label="`${$tc('label.descricao_planejamento_acao', 1)} *`"/>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="fechar" v-if="cancel">{{ $t('label.cancelar') }}</v-btn>
      <v-btn color="primary" @click="salvar">{{ $t('label.salvar') }}</v-btn>
    </v-card-actions>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoEdicaoMixin from './PlanejamentoAcaoEdicaoMixin';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';

export default {
  name: 'PlanejamentoAcaoEdicaoForm',
  props: {
    toolbar: {
      type: Boolean,
      default: false,
    },
    cardTitle: {
      type: Boolean,
      default: false,
    },
    isCard: {
      type: Boolean,
      default: true,
    },
    idAcao: null,
    acaoEdicao: null,
    cancel: {
      type: Boolean,
      default: false,
    },
    sm6: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      atualizarResource: this.$api.planejamentoAcaoAprovadaEdicao(this.$resource),

      metadadosParametrizado: null,
      acao: {},
      camposFormulario: {
        padrao: [],
      },
      valid: true,
      layoutClass: { wrap: true },
      colClass: { 'pl-0': true },
      inputLayout: {
        xs12: true,
        sm6: true,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  watch: {
    sm6(val) {
      this.inputLayout.sm6 = val;
    },
    acaoEdicao(val) {
      this.acao = { ...val };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  mixins: [
    PlanejamentoAcaoEdicaoMixin,
  ],
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  methods: {
    aplicaParametrizacoes() {
      this.metadadosParametrizado = this.configuracaoAcao
        .aplicaParametrizacao(this.getAcaoMetadado);
    },
    fechar() {
      this.$emit('PlanejamentoAcaoEdicaoForm__fechar');
    },
    salvar() {
      if (!this.$refs.formEdicaoAcao.validate()) return;
      const objetoFormatado = {
        id: this.acao.idAcao,
        idTipoAcao: this.configuracaoAcao.idAcaoTipo,
        descricao: this.acao.descricao,
        mapaCamposDinamicos: {},
      };
      const camposDinamicos = this.$refs.container.getValoresCamposDinamicos();

      this.camposEdicao.forEach((c) => {
        objetoFormatado.mapaCamposDinamicos[c] = camposDinamicos[c] || null;
      });
      this.atualizarResource.atualizarAcao(objetoFormatado)
        .then(() => {
          this.$toast(this.$t('message.acao_atualizada'));
          this.$emit('PlanejamentoAcaoEdicaoForm__atualizado');
          this.fechar();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarDadosAcao() {
      if (this.acaoEdicao) {
        this.acao = { ...this.acaoEdicao };
      } else {
        this.planejamentoAcaoListagemResource.buscarAcao({ idAcao: this.idAcao })
          .then((res) => {
            const { passoGeral } = res.data;
            this.acao = {
              id: this.idAcao,
              ...passoGeral,
              ...passoGeral.mapaCamposDinamicos,
            };
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
  },
  mounted() {
    this.aplicaParametrizacoes();
    this.inputLayout.sm6 = this.sm6;
    setTimeout(() => this.$emit('PlanejamentoAcaoEdicaoForm__montado'), 2E2);
    this.buscarDadosAcao();
  },
};
</script>
